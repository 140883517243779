/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/Layout/index'
import InputMask from 'react-input-mask'
import Pagina from '../components/Pagina'
import axios from 'axios'

const sendEvent = (action, label = 'none') => {
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push({
      event: 'promotor',
      eventAction: action,
      eventLabel: label
    })
  }
}

const AreadoPromotor = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/area-do-promotor/', name: 'Área do Promotor' }
    ]
  }

  const [formState, setFormState] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [clickEnvio, setClickEnvio] = useState(false)

  function validaEmail () {
    return !!formState.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
  }

  function validaCPF () {
    return !!formState.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/);
  }

  function handleFormValidation () {
    setSuccessMessage(false)
    if (formState && Object.keys(formState).length < 11) {
      setErrorMessage('Preencha todos os campos')
    } else if (formState.nome.match(/[0-9]/gi)) {
      setErrorMessage('Nomes não podem conter números!')
    } else if (formState.nome.length < 1) {
      setErrorMessage('O campo nome é obrigatório!')
    } else if (formState.nomeSuperior.length < 1) {
      setErrorMessage('O campo nome do superior é obrigatório!')
    } else if (formState.empresa.length < 1) {
      setErrorMessage('O campo empresa é obrigatório!')
    } else if (formState.rg.length < 1) {
      setErrorMessage('O campo RG é obrigatório!')
    } else if (!validaEmail()) {
      setErrorMessage('Digite um Email Válido!')
    } else if (!validaCPF()) {
      setErrorMessage('CPF Inválido!')
    } else if (formState.telefone.length < 12) {
      setErrorMessage('Telefone Inválido!')
    } else if (formState.telefoneSuperior.length < 12) {
      setErrorMessage('Celular Inválido!')
    } else if (formState.celular.length < 13) {
      setErrorMessage('Celular Inválido!')
    } else {
      return true
    }
    return false
  }

  function resetForm () {
    formState.nome = ''
    formState.empresa = ''
    formState.cpf = ''
    formState.email = ''
    formState.telefone = ''
    formState.celular = ''
    formState.rg = ''
    formState.nomeSuperior = ''
    formState.horarioEntrada = ''
    formState.horarioSaida = ''
    formState.telefoneSuperior = ''
  }

  async function enviarMensagem (event) {
    event.preventDefault()
    if (handleFormValidation()) {
      setClickEnvio(true)
      const payload = {
        nome: formState.nome,
        empresa: formState.empresa,
        cpf: formState.cpf,
        email: formState.email,
        telefone: formState.telefone,
        celular: formState.celular,
        rg: formState.rg,
        nomeSuperior: formState.nomeSuperior,
        horarioEntrada: formState.horarioEntrada,
        horarioSaida: formState.horarioSaida,
        telefoneSuperior: formState.telefoneSuperior,
        pagina: 'areapromotor'
      }
      try {
        await axios.post('https://0iynl7qv67.execute-api.us-east-1.amazonaws.com/prd/email', payload)
        sendEvent('form', formState.email)
        setErrorMessage(false)
        setSuccessMessage(<>Cadastro realizado com sucesso!</>)
        resetForm()
        setClickEnvio(false)
      } catch (error) {
        setSuccessMessage(false)
        setErrorMessage(<>Não foi possível concluir o cadastro. <br/> Por favor, verifique os dados e tente novamente! </>)
        setClickEnvio(false)
      }
    }
  }

  const setInput = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  // const [telMask, setTelMask] = useState([/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/])

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina}/>
      <section className="container mt-4">
        <div className="row">
          <div className="col-12">
            <h1>Área do Promotor</h1>
          </div>
        </div>
      </section>
      <div className="bg-gray2 mt-5">
        <div className="container pt-4 pb-4">
          <div className="row">
            <div className="col-12">
              <h2 className="text-black">Cadastro de Promotor</h2>
            </div>
          </div>
          <form className="w-100 card pt-3 pb-3 form-spani">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-2">
                  <label htmlFor="nome" className="mb-0">Nome Completo*</label>
                  <input type="text" id="nome" className="w-100" name="nome" onChange={(e) => setInput(e)} value={formState.nome} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="empresa" className="mb-0 mt-2">Empresa*</label>
                  <input type="text" id="empresa" className="w-100" name="empresa" onChange={(e) => setInput(e)} value={formState.empresa} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="cpf" className="mb-0 mt-2">CPF*</label>
                  <InputMask mask={'999.999.999-99'} placeholder="000.000.000-00" type="text" id="cpf" className="w-100" name="cpf" onChange={(e) => setInput(e)} value={formState.cpf} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="email" className="mb-0 mt-2">E-mail*</label>
                  <input type="text" id="email" className="w-100" name="email" onChange={(e) => setInput(e)} value={formState.email} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="telefone" className="mb-0 mt-2">Telefone*</label>
                  <InputMask mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} maskPlaceholder={null} type="text" id="telefone" className="w-100" name="telefone" value={formState.telefone} onChange={(e) => {
                    setInput(e)
                  }} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="celular" className="mb-0 mt-2">Celular*</label>
                  <InputMask mask={[/\d/, /\d/, ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} maskPlaceholder={null} type="text" id="celular" className="w-100" name="celular" value={formState.celular} onChange={(e) => {
                    setInput(e)
                  }} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="rg" className="mb-0 mt-2">RG*</label>
                  <input type="text" id="rg" className="w-100" name="rg" onChange={(e) => setInput(e)} value={formState.rg} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="nomeSuperior" className="mb-0 mt-2">Nome Superior*</label>
                  <input type="text" id="nomeSuperior" className="w-100" name="nomeSuperior" onChange={(e) => setInput(e)} value={formState.nomeSuperior} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="telefoneSuperior"className="mb-0 mt-2">Telefone Superior*</label>
                  <InputMask mask={[/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} maskPlaceholder={null} type="text" id="telefoneSuperior" className="w-100" name="telefoneSuperior" value={formState.telefoneSuperior} onChange={(e) => {
                    setInput(e)
                  }} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="horarioEntrada" className="mb-0 mt-2">Horário Entrada*</label>
                  <input type="time" id="horarioEntrada" className="w-100" name="horarioEntrada" onChange={(e) => setInput(e)} value={formState.horarioEntrada} />
                </div>
                <div className="col-lg-6 mb-2">
                  <label htmlFor="horarioSaida" className="mb-0 mt-2">Horário Saida*</label>
                  <input type="time" id="horarioSaida" className="w-100" name="horarioSaida" onChange={(e) => setInput(e)} value={formState.horarioSaida} />
                </div>
                <div className="col-12 mt-2">
                  <button className="btn btn-yellow d-flex w-100 text-uppercase justify-content-center font-openSans-extrabold" disabled={clickEnvio === true} onClick={(e) => (enviarMensagem(e))}>cadastrar</button>
                </div>
                {
                  !!errorMessage && (
                    <div className="col-lg-12">
                      <div className="d-flex bg-danger align-items-center justify-content-center py-2 px-4 my-1 rounded">
                        <p className="shadow rounded-circle text-white text-center align-self-center mb-0 mr-3"
                          style={{ width: '25px', height: '25px' }} >!</p>
                        <p className="text-center text-white mb-0 font-weight-bold w-75 fonterror">
                          {errorMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  !!successMessage && (
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-center bg-success rounded p-2 my-3">
                        <p
                          className="border text-white rounded-circle mb-0 mr-3 font-size-15 d-flex justify-content-center align-items-center"
                          style={{ width: '45px', height: '45px' }} >✔</p>
                        <p className="font-weight-bold text-white text-center font-size-1125 m-0">
                          {successMessage}
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-12 pt-4">
              <p className="font-size-08">(*) Campos obrigatórios</p>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default AreadoPromotor

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/area-do-promotor/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
  bgAplicativos: file(relativePath: {eq: "apps/Group.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 582, height: 467, layout: CONSTRAINED)
    }
  }
}
`
